// NOTE: fxLayoutGap doesn't do anything as far as I could tell, can just be removed. (08/07/2023 - Alex)

// fxLayout="column"
.fx-column {
	flex-direction: column;
	box-sizing: border-box;
	display: flex;
	flex: 1 1 100%;
}

// fxLayout="column" fxLayoutAlign="space-between start"
.fx-column-space-between-start {
	flex-direction: column;
	box-sizing: border-box;
	display: flex;
	place-content: flex-start space-between;
	align-items: flex-start;
}

// fxLayout="column" fxLayoutAlign="space-between center"
.fx-column-space-between-center {
	flex-direction: column;
	box-sizing: border-box;
	display: flex;
	place-content: center space-between;
	align-items: center;
}

// fxLayout="column" fxLayoutAlign="space-evenly start"
.fx-column-space-evenly-start {
	flex-direction: column;
	box-sizing: border-box;
	display: flex;
	place-content: flex-start space-evenly;
	align-items: flex-start;
}

// fxLayout="column" fxLayoutAlign="start center"
.fx-column-start-center {
	flex-direction: column;
	box-sizing: border-box;
	display: flex;
	place-content: center flex-start;
	align-items: center;
}

// fxLayout="column" fxLayoutAlign="start start"
.fx-column-start-start {
	flex-direction: column;
	box-sizing: border-box;
	display: flex;
	place-content: flex-start;
	align-items: flex-start;
}

// fxLayout="column" fxLayoutAlign="start end"
.fx-column-start-end {
	flex-direction: column;
	box-sizing: border-box;
	display: flex;
	place-content: flex-end flex-start;
	align-items: flex-end;
}


// fxLayout="column" fxLayoutAlign="center center"
.fx-column-center-center {
	flex-direction: column;
	box-sizing: border-box;
	display: flex;
	place-content: center;
	align-items: center;
}

// fxLayout="column" fxLayoutAlign="space-around center"
.fx-column-space-around-center {
	flex-direction: column;
	box-sizing: border-box;
	display: flex;
	place-content: center space-around;
	align-items: center;
}

// Flex: ROW

// fxLayout="row"
.fx-row {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
}

// fxLayout="row wrap"
.fx-row-wrap {
	flex-direction: row wrap;
	box-sizing: border-box;
	display: flex;
}

// fxLayout="row" fxLayoutAlign="none"
.fx-row-none {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	place-content: stretch flex-start;
	align-items: stretch;
}

// fxLaylout="row" fxLayoutAlign="start"
.fx-row-start {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	place-content: stretch flex-start;
	align-items: stretch;
}

// fxLaylout="row" fxLayoutAlign="start start"
.fx-row-start-start {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	place-content: flex-start;
	align-items: flex-start;
}


// fxLayout="row" fxLayoutAlign="space-between"
.fx-row-space-between {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	place-content: stretch space-between;
	align-items: stretch;
}

// fxLayout="row" fxLayoutAlign="space-between end"
.fx-row-space-between-end {
	flex-direction: row;  
	box-sizing: border-box;
	display: flex;
	place-content: flex-end space-between;
	align-items: flex-end;
}

// fxLayout="row" fxLayoutAlign="space-between start"
.fx-row-space-between-start {
	flex-direction: row;  
	box-sizing: border-box;
	display: flex;
	place-content: flex-start space-between;
	align-items: flex-start;
}

// fxLayout="row wrap" fxLayoutAlign="space-between center"
.fx-row-wrap-space-between-center {
	flex-flow: row wrap;
	box-sizing: border-box;
	display: flex;
	place-content: center space-between;
	align-items: center;
}

// fxLayout="row" fxLayoutAlign="space-between center"
.fx-row-space-between-center {
	flex-flow: row;
	box-sizing: border-box;
	display: flex;
	place-content: center space-between;
	align-items: center;
}


// fxLayout="row" fxLayoutAlign="space-evenly center"
.fx-row-space-evenly-center {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	place-content: center space-evenly;
	align-items: center;
}

// fxLayout="row" fxLayoutAlign="start center"
.fx-row-start-center {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	place-content: center flex-start;
	align-items: center;
}

// fxLayout="row" fxLayoutAlign="start end"
.fx-row-start-end {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	place-content: flex-end flex-start;
	align-items: flex-end;
}

// fxLayout="row" fxLayoutAlign="start stretch"
.fx-row-start-stretch {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	place-content: stretch flex-start;
	align-items: stretch;
}

// fxLayout="row" fxLayoutAlign="end center"
.fx-row-end-center {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	place-content: center flex-end;
	align-items: center;
}

// fxLayout="row" fxLayoutAlign="center center"
.fx-row-center-center {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	place-content: center;
	align-items: center;
}

// fxLayout="row" fxLayoutAlign="space-around center"
.fx-row-space-around-center {
	flex-direction: row wrap;
	box-sizing: border-box;
	display: flex;
	place-content: center space-around;
	align-items: center;
}

